import React, { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

const AuthCallback = ({ API_URL, showNotification }) => {
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    useEffect(() => {
        const queryParams = new URLSearchParams(window.location.search);
        const token = queryParams.get('token');

        if (token) {
            localStorage.setItem('refresh_token', token);
            fetch(`${API_URL}`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })
                .then(response => {
                    if (response.status === 200) {
                        navigate('/');
                    } else if (response.status === 401) {
                        setLoading(false);
                        throw new Error('Can\'t authentificate');
                    }
                })
                .catch(error => {
                    console.error(error);
                    showNotification(error.message, 'error');
                    navigate('/');
                })
        }
    }, [navigate, setLoading, API_URL, showNotification]);

    return (
        <Container className='text-center'>
            {loading ?
                <h1>Authentifizierung erfolgreich. Sie werden weitergeleitet...</h1> :
                <h1>Fehler bei der Authentifizierung. Bitte versuche es erneut.</h1>
            }
        </Container>
    );
};

export default AuthCallback;
