import React from 'react';
import SubredditManager from './SubredditManager';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const Footer = ({ reloadImages, reloadVideos, mode, changeMode, setLoading, API_URL, showNotification }) => {
    const resetVotes = () => {
        fetch(`${API_URL}/reset`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Reset not successful');
                }
                return response.json();
            })
            .then(showNotification('Reset successful', 'success'))
            .catch(error => {
                console.error(error);
                showNotification(error.message, 'error')
            });
    }

    return (
        <footer className='footer bg-dark text-white mt-auth py-3'>
            <Container>
                <Row className='justify-content-evenly'>
                    <Col className='d-flex justify-content-center'>
                        <SubredditManager className='align-content-center' reloadImages={reloadImages} reloadVideos={reloadVideos} mode={mode} setLoading={setLoading} API_URL={API_URL} showNotification={showNotification} />
                    </Col>
                    <Col className='d-flex justify-content-center'>
                        <Button className='align-content-center' variant='secondary' as={Link} to='/stats'>Statistik</Button>
                    </Col>
                    <Col className='d-flex justify-content-center'>
                        <Button className='align-content-center' variant='secondary' onClick={changeMode}>Change to {mode === 'images' ? 'Video': 'Image/GIF'}-Mode</Button>
                    </Col>
                    <Col className='d-flex justify-content-center'>
                        <Button className='align-content-center' variant='secondary' as={Link} to='/' onClick={resetVotes}>Reset</Button>
                    </Col>
                </Row>
            </Container>
        </footer>
    );
};

export default Footer;
