import React, { useRef, useState } from 'react';
import { Button, InputGroup, Modal, Form, ListGroup } from 'react-bootstrap';

const SubredditManager = ({ reloadImages, reloadVideos, mode, setLoading, API_URL, showNotification }) => {
  const [subreddits, setSubreddits] = useState([]);
  const [subreddit_list, setSubreddit_list] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [changes, setChanges] = useState(false);
  const [postamount, setPostamount] = useState(null);
  const [videoamount, setVideoamount] = useState(null);
  const [searchValue, setSearchValue] = useState('');
  const debounceTimeout = useRef(null);

  const manageSubreddit = (subreddit, method) => {
    if (method === 'add') {
      subreddit = document.getElementById('subreddit-input').value;
      document.getElementById('subreddit-input').value = '';
    }

    fetch(`${API_URL}/manageSubreddits`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ 'subreddit': subreddit, 'method': method })
    })
      .then(response => {
        if (!response.ok) {
          if (response.status === 400) {
            throw new Error('Failed to change subreddit list');
          } else if (response.status === 403) {
            throw new Error('Subreddit isn\'t nsfw');
          }
        }
        return response.json()
      })
      .then(() => {
        if (method === 'add') {
          showNotification('Loaded ' + subreddit, 'success');
        }
        setChanges(true);
        getSubreddits();
      })
      .catch(error => {
        console.error(error);
        showNotification(error.message, 'error');
      })
  }

  const getSubreddits = () => {
    fetch(`${API_URL}/subreddits`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then(response => {
        if (!response.ok) {
          throw new Error('Can\'t get current subreddits');
        }
        return response.json()
      })
      .then(data => {
        setSubreddits(data.subreddits);
        setPostamount(data.postamount);
        setVideoamount(data.videoamount);
        setShowModal(true);
      })
      .catch(error => {
        console.error(error);
        showNotification(error.message, 'error');
      })
  }

  const dismissModal = () => {
    if (subreddits.length > 0) {
      setShowModal(false);

      if (changes) {
        setLoading(true);
        if (mode === 'images') {
          reloadImages();
        } else {
          reloadVideos();
        }
      }
      setChanges(false);
    } else {
      showNotification('Select at least one subreddit', 'error');
    }
  }

  const searchSubreddits = (e) => {
    const value = e.target.value;
    setSearchValue(value);

    if (debounceTimeout.current) {
      clearTimeout(debounceTimeout.current);
    }

    debounceTimeout.current = setTimeout(() => {
      if (e.target.value.length > 0) {
        const params = new URLSearchParams({ 'search': e.target.value })
        fetch(`${API_URL}/search?${params}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json'
          }
        })
          .then(response => {
            if (!response.ok) {
              throw new Error('Can\'t find subreddits');
            }
            return response.json()
          })
          .then(data => {
            setSubreddit_list(data);
          })
          .catch(error => {
            console.error(error);
            showNotification(error.message, 'error');
          })
      }
    }, 500);
  }

  return (
    <div>
      <Button variant='secondary' onClick={getSubreddits}>Manage Subreddits</Button>

      <Modal show={showModal} onHide={dismissModal}>
        <Modal.Header closeButton>
          <Modal.Title>Subreddit Manager</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h2>Add Subreddit</h2>
          <InputGroup className='mb-3'>
            <Form.Control id='subreddit-input' type='search' list='subreddit-list' placeholder='Enter Subreddit' onChange={searchSubreddits} value={searchValue} />
            <Button variant='outline-secondary rounded-end-2' onClick={() => manageSubreddit('', 'add')}>Add Subreddit</Button>
            <datalist id='subreddit-list'>
              {subreddit_list.map((subreddit, index) => (
                <option value={subreddit} key={index} />
              ))}
            </datalist>
          </InputGroup>
          <h2>Current Subreddits</h2>
          <ListGroup>
            {subreddits.map(subreddit => (
              <ListGroup.Item className='d-flex justify-content-between align-items-center' key={subreddit}>
                <p className='m-0'>{subreddit}</p>
                <Button variant='danger' size='sm' className='float-end' onClick={() => manageSubreddit(subreddit, 'remove')}>X</Button>
              </ListGroup.Item>
            ))}
          </ListGroup>
          <p className='mb-0 mt-3'>Loaded posts: {postamount}</p>
          <p className='mb-0'>Loaded videos: {videoamount}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={dismissModal}>Close</Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default SubredditManager;
