import React, { useState, useEffect, useCallback } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import ImageContainer from './components/ImageContainer';
import Header from './components/Header';
import Footer from './components/Footer';
import Stats from './components/Stats';
import Notification from './components/Notification';
import Auth from './components/Auth';
import AuthCallback from './components/AuthCallback';
import { Container, Spinner } from 'react-bootstrap';

function App() {
  const [mode, setMode] = useState('images')
  const [images, setImages] = useState({});
  const [videos, setVideos] = useState({});
  const [loading, setLoading] = useState(true);
  const [notification, setNotification] = useState(null);
  const [authentificated, setAuthentificated] = useState(false);
  const API_URL = `${process.env.REACT_APP_API_URL}:8000`

  useEffect(() => {
    const token = localStorage.getItem('refresh_token');
    if (token) {
      setAuthentificated(true);
    }
  }, []);

  const getNewImages = useCallback(() => {
    fetch(`${API_URL}/images`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then(response => {
        if (!response.ok) {
          throw new Error('No images found');
        }
        return response.json();
      })
      .then(data => {
        setImages(data.posts);
        setLoading(false);
      })
      .catch(error => {
        console.error(error);
        showNotification(error.message, 'error');
      });
  }, [API_URL]);

  const getNewVideos = useCallback(() => {
    fetch(`${API_URL}/videos`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then(response => {
        if (!response.ok) {
          throw new Error('No videos found');
        }
        return response.json();
      })
      .then(data => {
        setVideos(data.posts);
        setLoading(false);
      })
      .catch(error => {
        console.error(error);
        showNotification(error.message, 'error');
      });
  }, [API_URL]);

  useEffect(() => {
    if (authentificated) {
      getNewImages();
      getNewVideos();
    }
  }, [getNewImages, getNewVideos, authentificated]);

  const handleVote = (vote) => {
    if (!loading) {
      setLoading(true);
      fetch(`${API_URL}/vote`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(JSON.parse(vote))
      })
        .then(response => {
          if (!response.ok) {
            throw new Error('Vote not successful');
          } else {
            if (mode === 'images') {
              getNewImages();
            } else {
              getNewVideos();
            }
          }
          return response.json()
        })
        .catch(error => {
          console.error(error);
          showNotification(error.message, 'error');
        });
    }
  };

  const showNotification = (message, type) => {
    setNotification({ message, type });
  };

  const logout = () => {
    fetch(`${API_URL}/logout`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    })
    localStorage.removeItem('refresh_token');
    setAuthentificated(false);
  };

  const changeMode = () => {
    if (mode === 'images') {
      setMode('videos');
      getNewVideos();
    } else {
      setMode('images');
      getNewImages();    
    }
  };

  return (
    <BrowserRouter>
      <div className='position-relative'>
        <div className='d-flex flex-column vh-100'>
          <Header API_URL={API_URL} authentificated={authentificated} logout={logout} />
          <Container className='d-flex flex-fill justify-content-center align-items-center mw-100'>
            <Routes>
              <Route path='/' element={authentificated ?
                <Container className='d-flex flex-column h-100 mw-100'>
                  <h1 className='text-center'>Which one is hotter? Click to choose</h1>
                  {loading !== true ?
                    <ImageContainer images={images} videos={videos} mode={mode} onVote={handleVote} />
                    :
                    <div className='loading d-flex justify-content-center align-items-center flex-fill'>
                      <Spinner animation='border' />
                    </div>
                  }
                </Container>
                :
                <Auth API_URL={API_URL} />
              } />
              <Route path='stats' element={<Stats API_URL={API_URL} showNotification={showNotification} />} />
              <Route path='callback' element={<AuthCallback API_URL={API_URL} showNotification={showNotification} />} />
            </Routes>
          </Container>
          <Footer reloadImages={getNewImages} reloadVideos={getNewVideos} mode={mode} changeMode={changeMode} setLoading={setLoading} API_URL={API_URL} showNotification={showNotification} />
        </div>
        {notification && <Notification message={notification.message} type={notification.type} onClose={() => setNotification(null)} />}
      </div>
    </BrowserRouter>
  );
}

export default App;