import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import './custom.css';

const ImageContainer = ({ images, videos, mode, onVote }) => (
  <Container className='d-flex justify-content-center align-items-center flex-fill'>
    <Row className='w-100 d-flex justify-content-around align-items-center'>
      <Col xs='12' md='5' className='d-flex justify-content-center align-items-center'>
        {mode === 'images' ?
          <div className='text-center image-card border rounded-3' style={{ cursor: 'pointer' }} onClick={() => onVote(JSON.stringify(images[0]))}>
            <h1>{images[0].title}</h1>
            <img className='rounded-0' src={images[0].url} alt={images[0].title} />
            <p>Subreddit: r/{images[0].subreddit}</p>
          </div>
          :
          <div className='text-center image-card border rounded-3' style={{ cursor: 'pointer' }} onClick={() => onVote(JSON.stringify(videos[0]))}>
            <h1>{videos[0].title}</h1>
            <iframe src={videos[0].url} title={images[0].title} />
            <p>Subreddit: r/{videos[0].subreddit}</p>
          </div>
        }
      </Col>
      <Col xs='12' md='2' className='d-flex justify-content-center align-items-center'>
        <strong style={{ cursor: 'default' }}>OR</strong>
      </Col>
      <Col xs='12' md='5' className='d-flex justify-content-center align-items-center'>
        {mode === 'images' ?
          <div className='text-center image-card border rounded-3' style={{ cursor: 'pointer' }} onClick={() => onVote(JSON.stringify(images[1]))}>
            <h1>{images[1].title}</h1>
            <img className='rounded-0' src={images[1].url} alt={images[1].title} />
            <p>Subreddit: r/{images[1].subreddit}</p>
          </div>
          :
          <div className='text-center image-card border rounded-3' style={{ cursor: 'pointer' }} onClick={() => onVote(JSON.stringify(videos[1]))}>
            <h1>{videos[1].title}</h1>
            <iframe src={videos[1].url} title={images[1].title} />
            <p>Subreddit: r/{videos[1].subreddit}</p>
          </div>
        }
      </Col>
    </Row>
  </Container>
);

export default ImageContainer;
