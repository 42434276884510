import React, { useEffect, useState } from 'react';
import { Button, Col, Container, Row, Spinner } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import './custom.css';

const Stats = ({ API_URL, showNotification }) => {
    const [stats, setStats] = useState(null);

    useEffect(() => {
        fetch(`${API_URL}/stats`, {
            method: 'GET',
            headers: {
                'Content-Type': 'applicatoin/json'
            }
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Can\'t get stats')
                }
                return response.json()
            })
            .then(data => {
                setStats(data);
            })
            .catch(error => {
                console.error(error);
                showNotification(error.message, 'error')
            });
    }, [API_URL, showNotification]);

    if (!stats) {
        return (
            <div className='loading d-flex flex-column justify-content-center align-items-center'>
                <Spinner animation='border' role='status' />
                <span className='mb-3 sr-only'>Loading...</span>
                <Button variant='secondary' as={Link} to='/'>Zurück</Button>
            </div>
        )
    }

    return (
        <Container className='text-center d-flex flex-column h-100'>
            <h1>Statistics</h1>
            <p>Total Votes: {stats.total_votes}</p>

            <h2>Top 5 Subreddits</h2>
            <div className='subreddit-list mb-3'>
                {stats.top_subreddits.map(([subreddit, count], index) => (
                    <div key={index} className='subreddit-item'>
                        <span>{subreddit}:</span>
                        <span>{count} votes</span>
                    </div>
                ))}
            </div>

            <h2>Top 5 Images</h2>
            <Row className='flex-fill'>
                {stats.top_images.map((data) => (
                    <Col key={data.id} className='image-col'>
                        <img className='top-image' src={data.url} alt={data.url} title={data.title} />
                        <p>{data.subreddit}</p>
                        <p>{data.votes} votes</p>
                    </Col>
                ))}
            </Row>
            <div className='mb-3'>
                <Button variant='secondary' as={Link} to='/'>Zurück</Button>
            </div>
        </Container>
    );
}

export default Stats;
