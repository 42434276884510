import { Toast, ToastContainer } from 'react-bootstrap';

const Notification = ({ message, type, onClose }) => {
    let textcolor = type === 'success' ? 'text-bg-success' : 'text-bg-danger';
    return (
        <ToastContainer className='top-0 end-0 mt-3 me-3'>
            <Toast className={`${textcolor} text-center`} onClose={onClose} delay={3000} autohide>
                <Toast.Body>{message}</Toast.Body>
            </Toast>
        </ToastContainer>
    );
}

export default Notification;