import { Button, Container } from 'react-bootstrap';

const Auth = ({ API_URL }) => {

    const handleLogin = () => {
        window.location.href = `${API_URL}/login`
    }

    return (
        <Container className='text-center'>
            <h1>Bitte Anmelden</h1>
            <Button variant='primary' onClick={handleLogin}>Mit Reddit anmelden</Button>
        </Container>
    );
}

export default Auth;